import React, { useEffect } from 'react';
import { Timer, ChevronRight, Medal } from 'lucide-react';
import mixpanel from 'mixpanel-browser';

interface QuizHeroProps {
  onStartQuiz: () => void;
}

export const QuizHero: React.FC<QuizHeroProps> = ({ onStartQuiz }) => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    window.addEventListener('resize', handleResize);

    try {
      mixpanel.track('Hero Page Visited', {
        page_name: 'SwimmerQuizHero',
        quiz_type: 'swimmer_personality'
      });

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: 'SwimmerQuizHero',
          page_path: '/hero',
          quiz_type: 'swimmer_personality'
        });
      }
    } catch (error) {
      console.error('Analytics tracking error:', error);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleStartQuiz = () => {
    try {
      mixpanel.track('Start Quiz Button Clicked', {
        page_name: 'SwimmerQuizHero',
        quiz_type: 'swimmer_personality'
      });

      if (window.gtag) {
        window.gtag('event', 'button_click', {
          event_category: 'SwimmerQuizHero',
          event_label: 'Start Quiz',
          quiz_type: 'swimmer_personality',
          value: 1,
        });
      }
    } catch (error) {
      console.error('Analytics tracking error:', error);
    }
    onStartQuiz();
  };

  return (
    <div className="min-h-screen bg-white flex flex-col lg:flex-row">
      {/* Left Panel - Visual Section */}
      <div className="lg:w-1/2 relative overflow-hidden bg-gradient-to-br from-blue-900 to-blue-600 flex items-center justify-center p-8">
        <div className="absolute inset-0 opacity-20">
          <div className="absolute inset-0" style={{
            backgroundImage: 'radial-gradient(circle, #ffffff 1px, transparent 1px)',
            backgroundSize: '30px 30px',
          }}></div>
        </div>
        
        <div className="relative z-10 text-white text-center">
          <div className="animate-bounce-slow">
            <Medal className="w-24 h-24 mx-auto mb-6 text-white/80" />
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Quick Stats</h2>
            <div className="grid grid-cols-2 gap-4 max-w-xs mx-auto">
              <div className="bg-white/10 p-4 rounded-lg backdrop-blur-sm">
                <div className="text-3xl font-bold">5+</div>
                <div className="text-sm opacity-80">Unique Types</div>
              </div>
              <div className="bg-white/10 p-4 rounded-lg backdrop-blur-sm">
                <div className="text-3xl font-bold">2m</div>
                <div className="text-sm opacity-80">Completion</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right Panel - Content Section */}
      <div className="lg:w-1/2 flex items-center justify-center p-8 lg:p-16">
        <div className="max-w-lg">
          <div className="mb-8">
            <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Discover Your Swimming Personality
            </h1>
            <p className="text-lg text-gray-600 leading-relaxed">
              Uncover your unique swimming style through our professionally crafted assessment. Get personalized insights and training recommendations.
            </p>
          </div>

          <div className="space-y-6">
            <div className="space-y-4">
              {[
                { icon: "🎯", text: "Personalized Analysis" },
                { icon: "⚡️", text: "Instant Results" },
                { icon: "🏊‍♂️", text: "Expert Insights" },
              ].map((feature, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <span className="text-2xl">{feature.icon}</span>
                  <span className="text-gray-700">{feature.text}</span>
                </div>
              ))}
            </div>

            <button
              onClick={handleStartQuiz}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-xl font-medium 
                       transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center 
                       space-x-2 group"
            >
              <Timer className="w-5 h-5" />
              <span>Start Your Assessment</span>
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>

            <p className="text-sm text-gray-500 text-center">
              Created with insights from professional swimmers and coaches
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        .animate-bounce-slow {
          animation: bounce-slow 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};