import { SwimStyle } from '../types/quiz';

export const swimStyles: SwimStyle[] = [
  {
    id: 'technician',
    title: 'The Technical Master',
    description: 'Youre obsessed with the perfect stroke. Every movement in the water is deliberate and refined. You find joy in the subtle adjustments that lead to efficiency, treating swimming as both an art and a science. Your attention to detail and form makes you an excellent mentor to others.',
    imageUrl: 'https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800&auto=format&fit=crop&q=60',
    traits: ['precise', 'analytical', 'perfectionistic', 'methodical'],
  },
  {
    id: 'endurance',
    title: 'The Distance Devotee',
    description: 'For you, swimming is about the long game. You thrive in lengthy training sessions and find your rhythm in sustained effort. Mental toughness is your superpower, and you excel at pacing yourself through challenging sets. The longer the distance, the more you come alive.',
    imageUrl: 'https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800&auto=format&fit=crop&q=60',
    traits: ['persistent', 'mentally tough', 'steady', 'focused'],
  },
  {
    id: 'competitor',
    title: 'The Racing Spirit',
    description: 'Competition fuels your passion for swimming. Youre energized by racing, whether against others or the clock. Your training is purposeful and targeted, always with specific performance goals in mind. You thrive under pressure and love the excitement of meet day.',
    imageUrl: 'https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800&auto=format&fit=crop&q=60',
    traits: ['competitive', 'goal-oriented', 'determined', 'ambitious'],
  },
  {
    id: 'mindful',
    title: 'The Zen Swimmer',
    description: 'Swimming is your moving meditation. You find peace in the rhythm of your strokes and the sensation of water flowing past. While you appreciate good technique and fitness, your primary focus is on the mental clarity and emotional balance that swimming provides.',
    imageUrl: 'https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800&auto=format&fit=crop&q=60',
    traits: ['mindful', 'balanced', 'introspective', 'composed'],
  },
  {
    id: 'versatile',
    title: 'The All-Rounder',
    description: 'You embody versatility in the pool. Equally comfortable sprinting or swimming distance, doing drills or racing sets, you adapt your swimming to your goals and mood. Your flexible approach keeps swimming fresh and engaging, and you excel at cross-training.',
    imageUrl: 'https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800&auto=format&fit=crop&q=60',
    traits: ['adaptable', 'well-rounded', 'experimental', 'balanced'],
  }
];