import { Question } from '../types/quiz';

export const questions: Question[] = [
  {
    id: 1,
    text: "During a typical training session, what aspect of swimming demands most of your attention?",
    imageUrl: "https://images.unsplash.com/photo-1519315901367-f34ff9154487?w=800",
    options: [
      { text: "Perfect form and technique in every stroke", styles: { technician: 3, endurance: 1, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Maintaining steady pace and rhythm", styles: { technician: 1, endurance: 3, competitor: 0, mindful: 2, versatile: 0 } },
      { text: "Hitting target times and improving speed", styles: { technician: 0, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "The feeling of flow and connection with the water", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 1 } },
    ],
  },
  {
    id: 2,
    text: "How do you prefer to recover after an intense swimming session?",
    imageUrl: "https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800",
    options: [
      { text: "Analyze my technique and performance data", styles: { technician: 3, endurance: 0, competitor: 2, mindful: 0, versatile: 1 } },
      { text: "Cool down with easy laps, focusing on form", styles: { technician: 2, endurance: 1, competitor: 0, mindful: 2, versatile: 1 } },
      { text: "Plan my next training session", styles: { technician: 1, endurance: 1, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Practice mindful breathing and stretching", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 2 } },
    ],
  },
  {
    id: 3,
    text: "When learning a new stroke or technique, what's your approach?",
    imageUrl: "https://images.unsplash.com/photo-1600965962324-aa9a0483c2cc?w=800",
    options: [
      { text: "Break it down into precise components", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Practice repeatedly until it feels natural", styles: { technician: 1, endurance: 3, competitor: 1, mindful: 1, versatile: 0 } },
      { text: "Focus on speed and efficiency gains", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Experiment with different variations", styles: { technician: 1, endurance: 0, competitor: 0, mindful: 1, versatile: 3 } },
    ],
  },
  {
    id: 4,
    text: "What motivates you most to keep swimming?",
    imageUrl: "https://images.unsplash.com/photo-1551406483-3731d1997540?w=800",
    options: [
      { text: "Mastering perfect technique", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Building endurance and strength", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Achieving competitive goals", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Mental clarity and well-being", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 1 } },
    ],
  },
  {
    id: 5,
    text: "How do you approach swim workouts?",
    imageUrl: "https://images.unsplash.com/photo-1560090995-01632a28895b?w=800",
    options: [
      { text: "Follow structured technical programs", styles: { technician: 3, endurance: 1, competitor: 1, mindful: 0, versatile: 0 } },
      { text: "Focus on distance and consistency", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 1, versatile: 0 } },
      { text: "Push intensity and track metrics", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Mix different styles based on feeling", styles: { technician: 0, endurance: 0, competitor: 0, mindful: 2, versatile: 3 } },
    ],
  },
  {
    id: 6,
    text: "What's your preferred type of feedback?",
    imageUrl: "https://images.unsplash.com/photo-1551958219-acbc608c6377?w=800",
    options: [
      { text: "Detailed technical analysis", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Progress on endurance metrics", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Race times and rankings", styles: { technician: 0, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "How the session felt overall", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 2 } },
    ],
  },
  {
    id: 7,
    text: "During a challenging set, what keeps you going?",
    imageUrl: "https://images.unsplash.com/photo-1595078475328-1ab05d0a6a0e?w=800",
    options: [
      { text: "Focusing on maintaining proper form", styles: { technician: 3, endurance: 1, competitor: 0, mindful: 1, versatile: 0 } },
      { text: "Finding a sustainable rhythm", styles: { technician: 0, endurance: 3, competitor: 0, mindful: 2, versatile: 1 } },
      { text: "Pushing to beat previous times", styles: { technician: 0, endurance: 1, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Staying present and mindful", styles: { technician: 1, endurance: 0, competitor: 0, mindful: 3, versatile: 1 } },
    ],
  },
  {
    id: 8,
    text: "How do you prepare for an important swim?",
    imageUrl: "https://images.unsplash.com/photo-1551958219-acbc608c6377?w=800",
    options: [
      { text: "Review technique and form", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Trust in consistent training", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 1, versatile: 0 } },
      { text: "Visualize racing strategy", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Center myself through meditation", styles: { technician: 0, endurance: 0, competitor: 0, mindful: 3, versatile: 2 } },
    ],
  },
  {
    id: 9,
    text: "What aspect of swimming brings you the most satisfaction?",
    imageUrl: "https://images.unsplash.com/photo-1530549387789-4c1017266635?w=800",
    options: [
      { text: "Executing perfect technique", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Completing challenging distances", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 1, versatile: 0 } },
      { text: "Achieving personal bests", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Finding flow in the water", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 1 } },
    ],
  },
  {
    id: 10,
    text: "How do you view swimming technique?",
    imageUrl: "https://images.unsplash.com/photo-1600965962324-aa9a0483c2cc?w=800",
    options: [
      { text: "A craft to perfect continuously", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Foundation for endurance", styles: { technician: 1, endurance: 3, competitor: 0, mindful: 1, versatile: 0 } },
      { text: "Tool for faster times", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Way to connect with water", styles: { technician: 0, endurance: 1, competitor: 0, mindful: 3, versatile: 1 } },
    ],
  },
  {
    id: 11,
    text: "What's your approach to swim training technology?",
    imageUrl: "https://images.unsplash.com/photo-1551958219-acbc608c6377?w=800",
    options: [
      { text: "Essential for technical analysis", styles: { technician: 3, endurance: 1, competitor: 1, mindful: 0, versatile: 0 } },
      { text: "Useful for tracking distance", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Critical for performance data", styles: { technician: 1, endurance: 0, competitor: 3, mindful: 0, versatile: 1 } },
      { text: "Prefer minimal technology", styles: { technician: 0, endurance: 0, competitor: 0, mindful: 3, versatile: 2 } },
    ],
  },
  {
    id: 12,
    text: "In a group training environment, you typically:",
    imageUrl: "https://images.unsplash.com/photo-1519315901367-f34ff9154487?w=800",
    options: [
      { text: "Share technical insights", styles: { technician: 3, endurance: 0, competitor: 1, mindful: 0, versatile: 1 } },
      { text: "Lead steady-pace sets", styles: { technician: 0, endurance: 3, competitor: 1, mindful: 1, versatile: 0 } },
      { text: "Initiate friendly races", styles: { technician: 0, endurance: 0, competitor: 3, mindful: 0, versatile: 2 } },
      { text: "Adapt to the group's focus", styles: { technician: 1, endurance: 1, competitor: 0, mindful: 1, versatile: 3 } },
    ],
  }
];