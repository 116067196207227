// QuestionCard.tsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface QuestionCardProps {
  question: {
    id: number;
    text: string;
    imageUrl: string;
    options: Array<{
      text: string;
      styles: Record<string, number>;
    }>;
  };
  selectedAnswer: number | null;
  onSelectAnswer: (index: number) => void;
}

export const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  selectedAnswer,
  onSelectAnswer,
}) => {
  return (
    <div className="w-full max-w-4xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        {/* Image Section with Gradient Overlay */}
        <div className="relative h-64 sm:h-96">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50">
            <img
              src={question.imageUrl}
              alt="Question visual"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <h2 className="text-2xl sm:text-3xl font-bold leading-tight">
              {question.text}
            </h2>
          </div>
        </div>

        {/* Options Grid */}
        <div className="p-6 sm:p-8 bg-white">
          <div className="grid gap-4">
            {question.options.map((option, index) => (
              <motion.button
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                onClick={() => onSelectAnswer(index)}
                className={`
                  relative overflow-hidden group p-6 rounded-xl text-left transition-all duration-300
                  ${selectedAnswer === index 
                    ? 'bg-blue-600 text-white shadow-lg scale-[1.02]' 
                    : 'bg-gray-50 hover:bg-gray-100 text-gray-700'}
                `}
              >
                {/* Decorative Elements */}
                <div className="absolute inset-0 opacity-10 bg-grid-pattern" />
                <div className={`
                  absolute top-0 left-0 w-1 h-full transition-all duration-300
                  ${selectedAnswer === index ? 'bg-white' : 'bg-blue-500 scale-y-0 group-hover:scale-y-100'}
                `} />

                {/* Option Content */}
                <div className="relative z-10">
                  <span className={`
                    inline-block w-8 h-8 rounded-full mb-3 text-sm font-medium flex items-center justify-center
                    ${selectedAnswer === index ? 'bg-white text-blue-600' : 'bg-blue-100 text-blue-600'}
                  `}>
                    {String.fromCharCode(65 + index)}
                  </span>
                  <p className="text-lg sm:text-xl font-medium">{option.text}</p>
                </div>

                {/* Hover Effect */}
                <motion.div
                  initial={false}
                  animate={{
                    scale: selectedAnswer === index ? 1.05 : 1,
                  }}
                  className="absolute inset-0 bg-blue-500 opacity-0 group-hover:opacity-5 transition-opacity"
                />
              </motion.button>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};