import { Question, SwimStyle } from '../types/quiz';
import { swimStyles } from '../data/swimStyles';

export function calculateSwimStyle(questions: Question[], answers: number[]): SwimStyle {
  // Initialize scores for all swim styles
  const styleScores: Record<string, number> = {};
  swimStyles.forEach(style => {
    styleScores[style.id] = 0;
  });

  // Calculate weighted scores based on answers
  answers.forEach((answerIndex, questionIndex) => {
    const question = questions[questionIndex];
    if (!question || answerIndex === undefined) return;
    
    const answer = question.options[answerIndex];
    if (!answer) return;
    
    // Weight later questions slightly more as they tend to be more specific
    const questionWeight = 1 + (questionIndex / questions.length * 0.5);
    
    Object.entries(answer.styles).forEach(([style, score]) => {
      if (styleScores.hasOwnProperty(style)) {
        styleScores[style] += score * questionWeight;
      }
    });
  });

  // Find primary and secondary styles
  const sortedStyles = Object.entries(styleScores)
    .sort(([,a], [,b]) => b - a);
  
  const primaryStyle = sortedStyles[0][0];
  const secondaryStyle = sortedStyles[1][0];
  
  // Find the primary style with influence from secondary
  const primaryStyleData = swimStyles.find(style => style.id === primaryStyle)!;
  const secondaryStyleData = swimStyles.find(style => style.id === secondaryStyle);
  
  // Add secondary style influence to description if scores are close
  if (secondaryStyleData && 
      (sortedStyles[0][1] - sortedStyles[1][1]) < 5) {
    primaryStyleData.description += ` You also show strong traits of a ${secondaryStyleData.title.toLowerCase()}.`;
  }

  return primaryStyleData;
}